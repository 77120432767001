import React, { useState, useEffect } from 'react'
import Button from 'components/Button'

const ButtonDownload = props => {
	const [active, setActive] = useState(false)

	useEffect(() => {
		let timeout
		if (active) timeout = setTimeout(() => setActive(false), 15 * 1000)
		return () => clearTimeout(timeout)
	}, [active])

	const { to, onClick } = props
	return (
		<Button
			className="text-nowrap"
			full
			icon="excel"
			size="sm"
			theme="success"
			isLink={!active}
			to={to}
			onClick={() => {
				setActive(true)
				onClick && onClick()
			}}
			disabled={active}
			download
			target="_blank"
			rel="nofollow noopener noreferrer"
		>
			{!active ? 'Скачать базу' : 'Скачивается'}
		</Button>
	)
}

export default ButtonDownload
