import React, { useContext } from 'react'
import Button from 'components/Button'
import PropTypes from 'prop-types'
import OrdersContext from 'contexts/orders'

const Cancel = props => {
	const { title, url } = props

	const ordersContext = useContext(OrdersContext)

	return (
		<div className="text-center">
			<h1 className="mb-lg">{title}</h1>
			{url && (
				<Button
					theme="success"
					icon="card"
					isLink
					to={url}
					onClick={() => {
						// Добавление ID заказа в LocalStorage для вывода подсказки после оплаты
						ordersContext.add(ordersContext.getClientOrderIdFromURL())
					}}
				>
					Попробовать снова
				</Button>
			)}
		</div>
	)
}

Cancel.propTypes = {
	title: PropTypes.string.isRequired,
	url: PropTypes.string,
}

export default Cancel
