import React from 'react'
import Icon from 'components/Icon'

const Loading = () => {
	return (
		<div className="text-center">
			<h1 className="mb">Обрабатываем заказ</h1>
			<p className="fs-h4 mb-xl">Пожалуйста, подождите</p>
			<Icon type="loading" size={120} color="#e8e8e8" />
		</div>
	)
}

export default Loading
