import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button'
import copy from 'copy-to-clipboard'

const Copy = props => {
	const { textBefore, textAfter, icon, theme, size, isNoBorder, callback } = props
	const [copied, setCopied] = useState(false)
	const { value } = props
	const timeout = useRef(null)

	const handleCopy = () => {
		clearTimeout(timeout.current)
		setCopied(true)
		timeout.current = setTimeout(() => setCopied(false), 2000)
		copy(value)
		if (callback) callback()
	}

	return (
		<Button icon={icon} theme={theme} size={size} isNoBorder={isNoBorder} onClick={handleCopy}>
			{!copied ? textBefore : textAfter}
		</Button>
	)
}

Copy.defaultProps = {
	textBefore: 'Копировать',
	textAfter: 'Скопировано',
}

Copy.propTypes = {
	value: PropTypes.string.isRequired,
	icon: PropTypes.string,
	size: PropTypes.oneOf(['xs', 'sm', 'lg']),
	theme: PropTypes.oneOf(['default', 'primary', 'danger', 'success', 'light', 'link']),
	isNoBorder: PropTypes.bool,
	callback: PropTypes.func,
}

export default Copy
